import React, { useContext, useState } from 'react'
import { Button, Modal, Progress, Spin, Statistic } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import { useFileAPI } from '../Hooks'
import Context from '../Utils/Context'
import useStream from '../Hooks/useStream'

export default function UploadAndAction({ title, Icon, color, action }) {
    const { Refresh, Message } = useContext(Context)
    const [status, setStatus] = useState('')
    const [upload, progress, handleCancel] = useFileAPI()
    const { request, data, cancelNew } = useStream((action) => {
        setStatus('')
        Refresh()
        Message.open({
            key: "UploadAndAction",
            type: action,
            content: title + ((action === 'success') ? ' Successfully' : (action === 'warning') ? " Cancel" : ' Failed'),
        });
    })

    const Uploading = async ({ file }) => {
        setStatus('uploading')
        let data = await upload(file)
        if (data) {
            setStatus('action')
            request(`/upload-and-action/${action}/${data.name}`)
        } else {
            Message.open({
                key: "UploadAndAction",
                type: 'error;',
                content: 'Uploading failed',
            })
            setStatus('')
        }
    }

    const cancel = () => {
        handleCancel()
        cancelNew()
    }

    return (
        <>
            <Modal
                title={
                    <div className='flex items-center gap-3 text-xl'>
                        <Icon style={{ color }} />
                        {title}
                    </div>}
                open={status}
                centered
                closeIcon={false}
                footer={false}
            >
                <div className="flex items-center gap-2 flex-col mt-6">
                    {(status === 'uploading') ? <>
                        <Spin size="large" />
                        <h1 className='text-xl'>Upload file on server</h1>
                        <Progress key='uploading' percent={progress} />
                        <Button type='primary' danger key="Cancel" onClick={cancel}>
                            Cancel Upload
                        </Button>
                    </> : (status === 'action') ? <>
                        <div className="flex items-center gap-3">
                            <Spin />
                            <Statistic value={data} />
                        </div>
                        <h1 className='text-gray-600'>Copying on Database</h1>
                        <Button type='primary' danger key="Cancel" onClick={cancel}>
                            Stop
                        </Button>
                    </> : <></>}
                </div>
            </Modal>
            <Dragger
                customRequest={Uploading}
                accept='.csv'
                showUploadList={false}
                className='bg-gray-100/50 hover:bg-gray-100 cursor-pointer !rounded-lg overflow-hidden flex flex-col items-center shadow-md '>
                <div className='p-3 rounded-xl m-5 relative overflow-hidden'>
                    <div className="absolute w-full h-full top-0 left-0 opacity-20" style={{ background: color }}></div>
                    <Icon style={{ color }} className='text-5xl ' />
                </div>
                <h3 className='text-xl text-gray-700 px-5'>{title}</h3>
                <div style={{ backgroundColor: color, }} className={`h-2 w-full mt-2`} />
            </Dragger>
        </>
    )
}
