import { useContext, useState } from "react";
import Context from "../Utils/Context";
import { useNavigate } from "react-router-dom";

const fileNameRegex = /^\d{13}-\d{9}\.zip$/;

export default function useStream(cb = () => {},download=()=>{}) {
  const [eventSource, setEventSource] = useState(null);
  const [data, setData] = useState("");
  const { token, SERVER } = useContext(Context);
  const BASEURL = SERVER + "/api";
  const navigate = useNavigate();

  const request = async (route = "", body = {}) => {
    try {
      if (!token) {
        navigate("/login");
        throw new Error("Please Login");
      }
      let response = await fetch(`${BASEURL}/refresh`, {
        method: "POST",
        body: JSON.stringify({ token }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status !== 200) {
        navigate("/login");
        throw new Error("Login Again");
      }

      const refresh = await response.json();
      let eventSource = new EventSource(
        `${BASEURL}/${route}?token=${refresh.token}&body=${JSON.stringify(
          body
        )}`
      );
      setEventSource(eventSource);

      eventSource.onmessage = (event) => {
        if (event.data === "Completed") {
          eventSource.close();
          cb("success");
          setData("");
        } else if (fileNameRegex.test(event.data)) {
          download(event.data);
        } else {
          setData(event.data);
        }
      };

      eventSource.onerror = (error) => {
        cb("error");
        setData("");
        eventSource.close();
        console.error("Error:", error);
      };
    } catch (error) {
      setData("");
      cb("error");
      console.error("Error:", error);
    }
  };

  const cancelNew = () => {
    cb("warning");
    setData("");
    if (eventSource) {
      eventSource.close();
    }
  };

  return { request, data, cancelNew };
}
